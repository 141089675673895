import React from "react";
import {HydraAdmin} from "@api-platform/admin";
import AuthProvider from "./services/authProvider";
import DataProvider from "./services/dataProvider";

function App() {
  return (
      <HydraAdmin
          entrypoint={process.env.REACT_APP_API_ENTRYPOINT}
          dataProvider={DataProvider}
          authProvider={AuthProvider}
      >
      </HydraAdmin>
  );
}

export default App;

